import { useLoaderData } from "react-router-dom"
import { getVeggie } from "../menu_items"
import MenuItem from "../components/menuItem";
import SubMenu from "../components/subMenu";

export async function loader() {
  const veggies = await getVeggie();
  return { veggies };
}

export default function Veggie() {
  const { veggies } = useLoaderData();
  const content = {
    name: 'Veggie',  
    bgImg: 'veggie_topic.png',
  }
  
  return (
    <div className="item4">
      <SubMenu content={content} />
      <div className="items-grid">
      {
        veggies.map( (item) => (
          <MenuItem key={item.id} item={ item } />
        ))
      }
      </div>
    </div>
  )
}