
export async function getAllItems() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/');
    let data = await response.json();
    return data;
}

export async function getNews(){
    let response = await fetch('https://tokyoroll-api.herokuapp.com/news');
    let data = await response.json();
    return data;
}

export async function getWorkingInfo(){
    let response = await fetch('https://tokyoroll-api.herokuapp.com/workingInfo');
    let data = await response.json();
    console.log(data)
    return data;
}

export async function getDesserts() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/desserts');
    let data = await response.json();
    return data;
}

export async function getVorspeisen() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/vorspeisen');
    let data = await response.json();
    return data;
}

export async function getHotSpecialRolls() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/hotSpecialRolls');
    let data = await response.json();
    return data;
}

export async function getMakiRolls() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/makiRolls');
    let data = await response.json();
    return data;
}

export async function getTempuraRolls() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/tempuraRolls');
    let data = await response.json();
    return data;
}

export async function getNigiri() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/nigiri');
    let data = await response.json();
    return data;
}

export async function getSalads() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/salads');
    let data = await response.json();
    return data;
}

export async function getBentoSashimi() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/bentoSashimi');
    let data = await response.json();
    return data;
}

// export async function getSossen() {
//     let response = await fetch('https://tokyoroll-api.herokuapp.com/sossen');
//     let data = await response.json();
//     return data;
// }

export async function getSets() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/sets');
    let data = await response.json();
    return data;
}

export async function getVeggie() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/veggie');
    let data = await response.json();
    return data;
}

export async function getWok() {
    let response = await fetch('https://tokyoroll-api.herokuapp.com/wok');
    let data = await response.json();
    return data;
}