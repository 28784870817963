import { useLoaderData } from "react-router-dom"
import MenuItem from "../components/menuItem";
import { getSalads } from "../menu_items"
import SubMenu from "../components/subMenu";

export async function loader() {
  const salads = await getSalads();
  return { salads };
}

export default function Salads() {
  const { salads } = useLoaderData();
  const content = {
    name: 'Salads',
    bgImg: 'saladsbg.png',
  }
  return (
    <div className="item4">
      <SubMenu content={content} />
      <div className="items-grid">
      {
        salads.map( (item) => (
          <MenuItem key={item.id} item={ item } />
        ))
      }
      </div>
    </div>
  )
}