import React from 'react'
import SubMenu from '../components/subMenu';
import './about_us.css';

export default function AboutUs() {
  const content = {
    name: 'About us',  
    bgImg: 'about_us.jpg',
  }
  
  return (
    <div className='item4'>
      <SubMenu content={content} />
      <p className='aboutus-text'>
          Liebe Kundinnen und Kunden, vielen Dank, dass Sie unsere Seite besuchen. <br/>
          Wir möchten Ihnen zum Dank natürlich einen kleinen Eindruck von uns und unserem Geschäft geben, <br/>
          damit Sie auch mal ein paar Einblicke hinter die Kulisse bekommen. <br/>
      </p>
      <img className='aboutus-img' src='site_content_images/about_us_pc.png' alt='about us' />
      <img className='aboutus-img-mobile' src='site_content_images/about_us_smart.png' alt='about us mobile' />
    </div>
  )
}
