import React from 'react';
import ReactDOM from 'react-dom/client';
import { 
  createBrowserRouter, 
  RouterProvider,
} from 'react-router-dom';
import './index.css';
import Root from './routes/root';
import ErrorPage from './error-page';
import Sets, { loader as setsLoader } from './routes/sets';
import Desserts, { loader as dessertsLoader } from './routes/desserts';
import Vorspeisen, { loader as extrasLoader } from './routes/vorspeisen';
import BentoSashimi, { loader as bentoSashimiLoader } from './routes/bentoSashimi';
import HotSpecialRolls, { loader as hotSpecialRollsLoader } from './routes/hotSpecialRolls';
import MakiRolls, { loader as makiRollsLoader } from './routes/makiRolls';
import Nigiri, { loader as nigiriLoader } from './routes/nigiri';
import Salads, { loader as saladsLoader } from './routes/salads';
import TempuraRolls, { loader as tempuraRollsLoader } from './routes/tempuraRolls';
import Veggie, { loader as veggieLoader } from './routes/veggie';
import Wok, { loader as wokLoader } from './routes/wok';
import Contact, { loader as contactLoader } from './routes/contact';
import AboutUs from './routes/about_us';
import Leistungen from './routes/leistungen';
import Agb from './routes/agb';
import Catering from './routes/catering';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: "/",
        element: <HotSpecialRolls />,
        loader: hotSpecialRollsLoader,
      },
      {
        path: "about/",
        element: <AboutUs />,
      },
      {
        path: "agb/",
        element: <Agb />,
      },
      {
        path: "catering/",
        element: <Catering />,
      },
      {
        path: "leistungen/",
        element: <Leistungen />,
      },
      {
        path: "sets/",
        element: <Sets />,
        loader: setsLoader,
      },
      {
        path: "desserts/",
        element: <Desserts />,
        loader: dessertsLoader,
      },
      {
        path: "vorspeisen/",
        element: <Vorspeisen />,
        loader: extrasLoader,
      },
      {
        path: "bentoSashimi/",
        element: <BentoSashimi />,
        loader: bentoSashimiLoader,
      },
      {
        path: "hotSpecialRolls/",
        element: <HotSpecialRolls />,
        loader: hotSpecialRollsLoader,
      },
      {
        path: "makiRolls/",
        element: <MakiRolls />,
        loader: makiRollsLoader,
      },
      {
        path: "nigiri/",
        element: <Nigiri />,
        loader: nigiriLoader,
      },
      {
        path: "salads/",
        element: <Salads />,
        loader: saladsLoader,
      },
      {
        path: "tempuraRolls/",
        element: <TempuraRolls />,
        loader: tempuraRollsLoader,
      },
      {
        path: "veggie/",
        element: <Veggie />,
        loader: veggieLoader,
      },
      {
        path: "wok/",
        element: <Wok />,
        loader: wokLoader,
      },
      {
        path: "contact/",
        element: <Contact />,
        loader: contactLoader,
      }
    ]
  },
]);
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
