import React from 'react'
import './agb.css';

export default function Agb() {
  return (
    <div className='item4'>
              
      <h2 className='text'>Lieferbedingungen/Allgemeine Geschaeftsbedingungen</h2>

      <h3 className='text'>1. Allgemeines</h3>

      <p className='text'>
        Für sämtliche Bestellungen bei Tokyo (Alexander Ufelman), im folgenden "Ware" genannt,
        durch Kunden in dem Betrieb von Tokyo (Alexander Ufelman), über deren Homepage
        oder über eine Bestellplattform gelten nachstehende Liefer-und Zahlungsbedingungen, 
        letzteren falls nur teilweise und sinngemäß, soweit der Liefervertrag unmittelbar 
        mit der Lieferplattform geschlossen wird. Diese Bedingungen sind auf dem Online-Portal 
        der Firma Tokyo (Alexander Ufelman) sowie im Webshop abrufbar und in dem örtlichen Betrieb einzusehen. 
        Die Liefer-und Zahlungsbedingungen werden von dem Kunden mit jeder Bestellung 
        von Waren gleich in welcher Form dies geschieht und spätestens mit Entgegennahme der Ware 
        als Inhalt des zwischen ihnen bestehenden Vertragsverhältnisses anerkannt.
      </p>

      <h3 className='text'>2. Vertragsschluss</h3>

      <p className='text'>
        Alle auf Flyern, auf der Website, in Anzeigen oder sonstigen Werbeaktionen im Imbiss 
        oder auf sonstige Weise von Tokyo (Alexander Ufelman) unterbreitete Angebot, gleich 
        ob die regulaeren Angebote gemaess Speisekarte oder Sonderofferten stellen eine Aufforderung 
        an den Kunden dar, durch Bestellung ein konkretes Kaufangebot zu unterbreiten. 
        Mit der Bestellung einer Ware erklaert der Kunde verbindlich, die bestellte Ware zu dem im Aushang, 
        im Flyer oder auf der Website genannten Preis erwerben zu wollen. 
        Tokyo (Alexander Ufelman) ist berechtigt, die Annahme dieses Vertragsangebotes in jeder Form, 
        insbesondere durch Auslieferung oder Uebergabe der Ware zu erklaeren. 
        Bestellt der Kunde die Ware auf elektronischem Weg, bestaetigt Tokyo (Alexander Ufelman) 
        die Bestellung unverzueglich. Die Bestaetigung des Zugangs stellt noch keine verbindliche Annahme 
        der Bestellung dar. Der Vertragstext der Bestellung auf elektronischem Weg wird 
        von Tokyo (Alexander Ufelman) mit den vom Kunden bei der Bestellung genannten persoenlichen Daten gespeichert. 
        Bei Schreib-, Druck-und Rechenfehler in dem Flyer bzw. auf der Website oder sonstigen Produktdarstellungen 
        ist Tokyo (Alexander Ufelman) zur korrekten Preisberechnung berechtigt. 
        Der Kunde hat in diesem Fall ein uneingeschraenktes Ruecktrittsrecht vom Liefervertrag. 
        Die im Flyer bzw. auf der Website oder sonstigen Produktdarstellungen von Tokyo. 
        (Alexander Ufelman) wiedergegebenen Bilder sind symbolisch; die Waren koennen in Ausfuehrung, 
        Gestaltung und Farbe abweichen. Die Qualitaet der Ware wird von uns stets geprueft und 
        durch unser Qualitaetsmanagement stets gewaehrleistet.
      </p>
      <h3 className='text'>3. Bezahlung / Eigentumsvorbehalt</h3>
      <p className='text'>
        Bei der Bezahlung der Ware im Zeitpunkt der Ausgabe im Betrieb von Tokyo (Alexander Ufelman) hat der Kunde, 
        vorbehaltlich der Einrichtung aller und/oder anderer Zahlungssysteme durch den oertlichen 
        Betrieb von Tokyo (Alexander Ufelman) die Wahl zwischen folgenden Zahlungsarten: Barzahlung 
        (bei dem Bestellverfahren ueber den Webshop erfolgt die Eingabe des Namens, der Lieferadresse 
        mit der Barzahlungsanweisung, wodurch der Rechnungsbetrag beim Ausliefern der Ware bezahlt wird). 
        Online Zahlung ueber PayPal (bei dem Bestellverfahren ueber den Webshop sendet der Kunde eine 
        Zahlung in Hoehe des Rechnungsbetrages ueber PayPal, wobei der Zahlungsauftrag ueber ein PayPal - 
        Zahlungskonto des Kunden ausgefuehrt und der angewiesene Betrag dem Zahlungsdienstleister des Empfaengers gutgeschrieben wird).
      </p>
      <h3 className='text'>4. Mängelhaftung</h3>

      <p className='text'>
        Tokyo (Alexander Ufelman) uebernimmt keine Beschaffenheitsgarantie oder sonstige Garantien. 
        Der Kunde wird die Ware bei Uebergabe auf aeussere Beschaedigungen und offensichtliche Fehler 
        ueberpruefen und gegebenenfalls sofort reklamieren. Ist die gelieferte Ware mangelhaft, 
        kann der Kunde nach Erfuellung durch Ersatzlieferung verlangen. Schlaegt die Nacherfuellung fehl, 
        kann der Kunde bei einem nicht unerheblichen Mangel vom Kaufvertrag zuruecktreten, den Kaufpreis 
        mindern oder Schadenersatz geltend machen. Weitergehende Ansprueche des Kunden, gleich aus welchem 
        Rechtsgrund, sind ausgeschlossen, soweit sich nicht nachstehend anderes ergibt. Die Hoehe des 
        Schadensersatzes ist auf die Hoehe des Bruttowarenwertes der mangelhaften Ware beschraenkt. 
        Tokyo (Alexander Ufelman) haftet nicht fuer Schaeden, die nicht am Liefergegenstand selbst entstanden sind; 
        insbesondere ist eine Haftung von Tokyo (Alexander Ufelman) fuer entgangenen Gewinn oder fuer 
        sonstige Vermoegensschaeden des Kunden ausgeschlossen. Soweit die Haftung von Tokyo (Alexander Ufelman) 
        ausgeschlossen oder beschraenkt ist, gilt dies auch fuer die persoenliche Haftung von Arbeitnehmern, 
        Vertretern und sonstigen Erfuellungsgehilfen von Tokyo (Alexander Ufelman). Saemtliche vorstehenden 
        Haftungsbeschraenkungen gelten nicht, soweit die Schadensursache auf Vorsatz oder grober 
        Fahrlaessigkeit beruht oder ein Personenschaden (Verletzung von Leben, Koerper oder Gesundheit) 
        vorliegt. Die Haftungsbeschraenkung gilt auch dann nicht, wenn der Besteller Ansprueche aus Absatz 1 
        und 4 PHG besitzt. Verletzt Tokyo (Alexander Ufelman) eine vertragswesentliche Pflicht fahrlaessig, 
        ist die Ersatzpflicht von Tokyo (Alexander Ufelman) auf den typischerweise bei Geschaeften der 
        fraglichen Art entstehenden Schaeden beschraenkt. Wesentliche Vertragspflichten sind solche, 
        deren Erfuellung die ordnungsgemaesse Durchfuehrung des Vertrages ueberhaupt erst moeglich 
        machen und auf deren Einhaltung der Vertragspartner regelmaessig vertraut und vertrauen darf 
        oder die Tokyo (Alexander Ufelman) dem Vertragspartner nach dem Vertragsinhalt gerade zu gewaehren hat.
      </p>
      <h3 className='text'>5. Datenschutz/Jugendschutz</h3>

      <p className='text'>
        Die im Rahmen der Durchfuehrung des zwischen dem Kunden und Tokyo (Alexander Ufelman) 
        zustande gekommenen Vertrages gespeicherten Kundendaten beinhalten den Namen, die Anschrift 
        der dazugehoerigen Postleitzahl, die Telefon-und oder Faxnummer, gegebenenfalls die vom Kunden 
        bekannt gegebene E-Mail-Adresse und eventuell von Kunden bekannt gegebene Zahlungsverbindungen. 
        Der Kunde erklaert mit der Warenbestellung sein Einverstaendnis, dass die von Tokyo (Alexander Ufelman) 
        gemaess vorstehender Ziffer gespeicherten Kundendaten innerhalb des Tokyo (Alexander Ufelman) Systems 
        intern verarbeitet und zum Zwecke der Marktanalyse und personalisierte Werbung verwendet werden darf 
        und den hieran beteiligten Unternehmen uebermittelt werden darf. Tokyo (Alexander Ufelman) wird das 
        Jugendschutzgesetz beruecksichtigen. Der Kunde ist verpflichtet, bei der Bestellung von dem Jugendschutzgesetz 
        unterfallenden Waren den entsprechenden Altersnachweis zu fuehren. Der Kunde erklaert mit der Bestellung, 
        dass er in der Lage ist, den Altersnachweis bei dem Jugendschutzgesetz unterfallenden Waren zu fuehren.
      </p>
      <h3 className='text'>6. Sonstige Regelungen</h3>

      <p className='text'>
        Tokyo (Alexander Ufelman) uebernimmt keine Haftung fuer die mit Ihrer Homepage/Website verlinkten Seiten. 
        Fuer saemtliche Inhalte, Angaben und Hinweise, die auf den durch Links auf der Homepage/Website von Tokyo 
        (Alexander Ufelman) erreichbaren Internet-Seiten enthalten sind, sind ausschliesslich die Betreiber bzw. 
        Inhaber dieser Internetseiten verantwortlich. Dies gilt auch fuer alle Dienste, Versprechungen und alle 
        sonstigen Moeglichkeiten, die dort genutzt werden koennen. Fuer alle Rechtsbeziehungen zwischen Tokyo 
        (Alexander Ufelman) und dem Kunden aufgrund der Bestellung von Waren und zur Ausgabe oder Auslieferung 
        an den Kunden gilt ausschliesslich des materiellen und formellen Rechts der Bundesrepublik Deutschland. 
        Erfuellungsort ist der Ort, an dem die bestellte Ware an den Kunden ausgegeben oder ausgeliefert wird. 
        Ausschliesslicher Gerichtsstand fuer alle Streitigkeiten aus der Geschaeftsbeziehung zwischen Tokyo (Alexander Ufelman) 
        und dem Kunden ist das am Geschaeftssitz von Tokyo (Alexander Ufelman) zustaendige Gericht, wenn der Kunde Kaufmann 
        oder juristische Person des privaten oder oeffentlichen Rechts ist. Haben die Vertragsparteien sich bei dem Vertrag, 
        den beide Seiten als geschlossen ansehen, ueber einen Punkt, ueber den eine Vereinbarung getroffen werden sollte, 
        tatsaechlich nicht geeignet, so ist Tokyo (Alexander Ufelman) unter Beruecksichtigung und in Ergaenzung der 
        getroffenen Vereinbarungen berechtigt, die Regelungsluecke unter Beruecksichtigung der beiderseitigen Interessen 
        nach billigem Ermessen zu schliessen. Sind einzelne Bestimmungen des Vertrages zwischen dem Kunden und Tokyo 
        (Alexander Ufelman) oder dieser Allgemeinen Liefer-und Zahlungsbedingungen nichtig oder unwirksam, 
        so beruehrt das die Wirksamkeit des Liefervertrages Ganzen und der uebrigen Zahlungsbestimmung nicht.
      </p>
    </div>
  )
}
