import { useLoaderData } from "react-router-dom"
import { getNigiri } from "../menu_items"
import MenuItem from "../components/menuItem";
import SubMenu from "../components/subMenu";

export async function loader() {
  const nigiris = await getNigiri();
  return { nigiris };
}

export default function Nigiri() {
  const { nigiris } = useLoaderData();
  const content = {
    name: 'Nigiri',  
    bgImg: 'nigiris.png',
  }
  
  return (
    <div className="item4">
      <SubMenu content={content} />
      <div className="items-grid">
      {
        nigiris.map( (item) => (
          <MenuItem key={item.id} item={ item } />
        ))
      }
      </div>
    </div>
  )
}