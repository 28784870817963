import React from 'react';
import './footer.css';

export default function Footer() {
  const str = "A: Glutenhaltiges Getreide|B: Weizen*|J: Sojabohnen*|C: Roggen*|K: Milch*|D: Gerste*|M: Senf*|E: Hafer*|N: Sesamsamen*|O: Weichtiere*|G: Eier*|* und daraus gewonnene Erzeugnisse|I: Erdnüsse*|F: Krebstiere*|H: Fisch"
  const additives = str.split("|")
  return (
    <div className='item5'>
      <p style={{textAlign: "center", fontSize: "2em"}}>Zusatzstoffe:</p>
      <div className='additives'>
        { additives.map((additive) => (
          <p className='additive'>{additive}</p>
        )) }
      </div>
      <p style={{textAlign: "center"}}>Copyright © 2022-2023. All Rights Reserved</p>
    </div>
  )
}
