import React from 'react'
import { Link } from 'react-router-dom';
import './subMenu.css';

export default function SubMenu({content}) {
  const ImgUrl = 'site_content_images/' + content.bgImg;
  let counter = 0;

  return (
    <div className='submenu'>
      <img className='submenu-img' src={ImgUrl} alt="background"/>
      <p className='submenu-name'>{content.name}</p>
      <div className='submenu-links'>
        { content.links?.map((link) => (
          <Link className='submenu-link' to={link}>{content.subMenuNames[counter++]}</Link>
        )) }
      </div>
    </div>
  )
}
