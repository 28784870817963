import { Link } from 'react-router-dom'
import React from 'react'
import './menuBar.css';

export default function MenuBar() {
  return (
    <div className='item3'>
      <ul className='menubar-list'>
        <li className='menubar-item'>
          <Link to={'salads'} className='menubar-link'>
            <img className='menubar-img' src='site_content_images/salads_icon.png' alt='salads icon'/>
            <p className='menubar-text'>Salads</p>
          </Link>
        </li>
        <li className='menubar-item'>
          <Link to={'bentoSashimi'} className='menubar-link'>
            <img className='menubar-img' src='site_content_images/bento_icon.png' alt='salads icon'/>
            <p className='menubar-text'>Bento Sashimi</p>
          </Link>
        </li>
        <li className='menubar-item'>
          <Link to={'wok'} className='menubar-link'>
            <img className='menubar-img' src='site_content_images/wok_icon.png' alt='wok icon'/>
            <p className='menubar-text'>Wok</p>
          </Link>
        </li>
        <li className='menubar-item'>
          <Link to={'vorspeisen'} className='menubar-link'>
            <img className='menubar-img' src='site_content_images/vorspeisen_icon.png' alt='extras icon'/>
            <p className='menubar-text'>Vorspeisen</p>
          </Link>
        </li>
        <li className='menubar-item'>
          <Link to={'makiRolls'} className='menubar-link'>
            <img className='menubar-img' src='site_content_images/maki_roll_icon.png' alt='maki rolls icon'/>
            <p className='menubar-text'>Maki Rolls</p>
          </Link>
        </li>
        <li className='menubar-item'>
          <Link to={'tempuraRolls'} className='menubar-link'>
            <img className='menubar-img' src='site_content_images/tempura_roll_icon.png' alt='tempura rolls icon'/>
            <p className='menubar-text'>Tempura Rolls</p>
          </Link>
        </li>
        <li className='menubar-item'>
          <Link to={'hotSpecialRolls'} className='menubar-link'>
            <img className='menubar-img' src='site_content_images/hot_special_roll_icon.png' alt='sushi & rolls icon'/>
            <p className='menubar-text'>Hot Special Rolls</p>
          </Link>
        </li>
        <li className='menubar-item'>
          <Link to={'nigiri'} className='menubar-link'>
            <img className='menubar-img' src='site_content_images/nigiri_icon.png' alt='nigiri icon'/>
            <p className='menubar-text'>Nigiri</p>
          </Link>
        </li>
        <li className='menubar-item'>
          <Link to={'veggie'} className='menubar-link'>
            <img className='menubar-img' src='site_content_images/veggie_icon.png' alt='veggie icon'/>
            <p className='menubar-text'>Veggie</p>
          </Link>
        </li>
        <li className='menubar-item'>
          <Link to={'sets'} className='menubar-link'>
            <img className='menubar-img' src='site_content_images/sets_icon.png' alt='sets icon'/>
            <p className='menubar-text'>Sets</p>
          </Link>
        </li>
        <li className='menubar-item'>
          <Link to={'desserts'} className='menubar-link'>
            <img className='menubar-img' src='site_content_images/dessert_icon.png' alt='dessert icon'/>
            <p className='menubar-text'>Desserts</p>
          </Link>
        </li>

        {/* <li>
          <Link to={'sossen'}>Soßen</Link>
        </li> */}
      </ul>
    </div>
  )
}
