import React from 'react';
import { useLoaderData } from "react-router-dom"
import { getWorkingInfo } from "../menu_items";
import SubMenu from '../components/subMenu';
import './contact.css';



export async function loader() {
  const workingInfo = await getWorkingInfo();
  return { workingInfo };
}


export default function Contact() {
  const { workingInfo } = useLoaderData();
  const content = {
    name: 'Kontakten',  
    bgImg: 'contact.jpg',
  }
  return (
    <div className='item4'>
      <SubMenu content={content} />
        <div className='contact'>
                {workingInfo.map(item => (
                    <p key={item.id} className="opentime-text">
                        {item.text.split('\r\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br/>
                            </React.Fragment>
                        ))}
                    </p>
                ))}

            <p className='requisiten-text'></p>
            <div className='contact-map'>
                <iframe className="map"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10376.442104027623!2d6.6360622!3d49.444628!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe8a7bea15c8f9bd8!2sTokyo!5e0!3m2!1sde!2sde!4v1644433320830!5m2!1sde!2sde"
                        title='Restaurant location' allowFullScreen="" loading="lazy"></iframe>
            </div>
            <div className='contact-text'>
                <div className='requisiten'>
                    <img className='requisiten-icon' src='site_content_images/phone.png' alt='contact phone'/>
                    <p className='requisiten-text'>+49 6861 8088524</p>
                </div>
                <div className='requisiten'>
                    <img className='requisiten-icon' src='site_content_images/email.png' alt='contact email'/>
                    <p className='requisiten-text'>tokyo-merzig@hotmail.com</p>
                </div>
                <div className='requisiten'>
                    <img className='requisiten-icon' src='site_content_images/address.png' alt='address'/>
                    <p className='requisiten-text'>Trierer Str. 45, 66663 Merzig</p>
                </div>
            </div>
        </div>
    </div>
  )
}
