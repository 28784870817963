import { useState } from 'react';
import { Outlet } from "react-router-dom";
import Logo from "../components/logo";
import Footer from "../components/footer";
import MenuBar from "../components/menuBar";
import NavBar from "../components/navBar";
import Award from "../components/award";
import NewsMarquee from '../components/news_marquee';

export default function Root() {
  
  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen(!open);
    const nav = document.getElementsByClassName('item2');
    open ? nav[0].style.display = 'flex' : nav[0].style.display = 'none';
  }

  return (
    <div className="grid-container">
      <Logo />
      <Award />
      <button className='dr-dwn' onClick={handleOpen}>
        {
          open ? (
            <img className='tripple-line' src='site_content_images/drdwn.png' alt='dropdown' />
          ) : (
            <img className='cross' src='site_content_images/cross.png' alt='cross' />
          )
        }
      </button>
      <NewsMarquee className="news-marquee" />
      <NavBar />
      <MenuBar />

      <Outlet />

      <Footer />
    </div>
  );
}
