import './logo.css';

export default function Logo() {
    return (
        <div className='item1'>
            <h1 id="logo-text-left" className='logo-text'>Tokyo</h1>
            <img src="site_content_images/logo.png" alt='Logo' className="logo" />
            <h1 id="logo-text-right" className='logo-text'>Roll</h1>
        </div>
    )
}