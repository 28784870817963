import React from 'react'
import './menuItem.css';

export default function MenuItem({ item }) {
  return (
    <div className='item'>
      <p className='item-name'>{ item.food_name }</p>
      <img className='item-img' src={item.food_image_url} alt="could not load" />
      <div className='item-description'>
      {
        item.food_description.split("|").map((str) => (
          <p className='description-text'>{ str }</p>
        ))
      }
      </div>
      <div className='item-price' >{ item.price }€</div>
    </div>
  )
}
