import React from 'react';
import SubMenu from '../components/subMenu';
import './catering.css';


export default function Catering() {
    const content = {
        name: 'Catering',  
        bgImg: 'catering_seafood.png',
    }

  return (
    <div className='item4'>
      <SubMenu content={content} />
      <p className='catering-text'>
          Ob Geburtstag, Hochzeit oder Weihnachtsfeier im Unternehmen -  <br/>
          Tokyo-Roll liefert Euch schmackhafte Sushi, Sashimi, Rollen, Wok und mehr schnell und zuverlässig.<br/>
          Kontaktieren Sie uns während unserer Arbeitszeit.<br/>
      </p>
    </div>
  )
}
