import React, { useState, useEffect } from 'react';
import { getNews } from '../menu_items';
import './news_marquee.css';

const NewsMarquee = () => {
  const [news, setNews] = useState('');

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const newsData = await getNews();
        const newsText = newsData.map(item => item.text).join(' *** ');
        setNews(newsText);
      } catch (error) {
      }
    };
    fetchNews();
  }, []);

  return (
    <div className="news-marquee">
      <div className="news-marquee-content">{news}</div>
    </div>
  );
};

export default NewsMarquee;