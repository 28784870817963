import React from 'react'
import './award.css';

export default function Award() {

  return (
    <div className='itemAward'>
        <a className='award-link' href='https://de.restaurantguru.com/Sushi-2-Go-Tokio-Roll-Dillingen'>
            <img className='award-img' src="site_content_images/award.png" alt='Logo' />
        </a> 
    </div>
  )
}
