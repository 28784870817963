import React from 'react'
import SubMenu from '../components/subMenu';
import './leistungen.css';

export default function Leistungen() {
  const content = {
    name: 'Unsere Leistungen',  
    bgImg: 'leistungen_bg.jpg',
  }
  
  return (
    <div className='item4'>
      <SubMenu content={content} />
      <img className='leistungen-img' src='site_content_images/new_Leistungen_pc.png' alt='unsere leistungen' />
      <img className='leistungen-img-mobile' src='site_content_images/new_Leistungen_mobile.png' alt='unsere leistungen' />
    </div>
  )
}
