import React from 'react'
import { Link } from 'react-router-dom'
import './navBar.css';

export default function NavBar() {

  return (
    <div className='item2' >
      <ul className='navbar-list'>
        <li className='navbar-item'>
          <Link to={'sets'} className='navbar-text'>Menü</Link>
        </li>
        <li className='navbar-item'>
          <Link to={'contact'} className='navbar-text'>Kontakt</Link>
        </li>
        <li className='navbar-item'>
          <Link to={'leistungen'} className='navbar-text'>Leistungen</Link>
        </li>
        <li className='navbar-item'>
          <Link to={'about'} className='navbar-text'>Über Uns</Link>
        </li>
        <li className='navbar-item'>
          <Link to={'agb'} className='navbar-text'>AGB</Link>
        </li>
      </ul>
    </div>
  )
}
